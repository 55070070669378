import { action, observable, computed, reaction, makeObservable } from "mobx";
import { addSeconds } from "date-fns";
import { SessionApiResponse } from "utils/account/session/session-api-response-type";
import { LocalStorage } from "../utils/localstorage";
import { ActiveUserPackage } from "domain/User/Account/utils/dashboard-api";
import { currentDate } from "../utils/date/currentDate";
import { IntervalService } from "../utils/date/interval.service";
import { isSocialLoginMethod } from "../utils/config/available-login-methods";

export enum AccountType {
  Guest = "guest",
  Authenticated = "authenticated",
}

class UserStore {
  private key: string = "user_store";
  private localStorage: LocalStorage;

  @observable
  public email: string;

  @observable
  public loginMethod: string;

  @observable
  public uuid: string;

  @observable
  public newAccount: boolean;

  @observable
  public lastActivePackages: ActiveUserPackage;

  @observable
  public isAuthenticated: boolean;

  @observable
  public isSocialLogin: boolean;

  @observable
  private expireLoginDate: Date;

  private accountType: AccountType;

  private unregisterFromInterval: Function = () => null;

  private intervalService: IntervalService;

  constructor() {
    makeObservable(this);
    this.localStorage = new LocalStorage(this.key);
    this.intervalService = new IntervalService();
    this.clear();

    this.loadDataFromLocalStorage();

    reaction(
      () => this.toJSON,
      (data) => {
        this.localStorage.saveDataToLocalStorage(data);
      }
    );

    this.checkAuthentication();

    if (this.isAuthenticated) {
      this.runIntervalChecking();
    }
  }

  private stopIntervalChecking() {
    this.unregisterFromInterval();
  }

  private runIntervalChecking() {
    this.stopIntervalChecking();
    this.unregisterFromInterval = this.intervalService.register(this.checkAuthentication.bind(this));
  }

  private isExpired(): boolean {
    const currentTime = currentDate().getTime();
    return this.expireLoginDate.getTime() < currentTime;
  }

  private setLoginMethod(loginMethod: string) {
    this.loginMethod = loginMethod;
    this.isSocialLogin = isSocialLoginMethod(loginMethod);
  }

  @action
  private checkAuthentication() {
    if (this.accountType == AccountType.Guest) {
      this.isAuthenticated = false;
      this.stopIntervalChecking();
      return;
    }

    if (this.isExpired()) {
      this.clear();
    } else {
      this.isAuthenticated = true;
    }
  }

  @action
  login({ loginExpiresIn, userInfo }: SessionApiResponse) {
    this.expireLoginDate = addSeconds(currentDate(), loginExpiresIn);
    this.email = userInfo.email;
    this.uuid = userInfo.uuid;
    this.accountType = AccountType.Authenticated;
    this.setLoginMethod(userInfo.login_method);
    this.checkAuthentication();
    this.runIntervalChecking();
  }

  @action
  setEmail(email: string) {
    this.email = email;
  }

  @action
  setUuid(uuid: string) {
    this.uuid = uuid;
  }

  @action
  setLastActiveUserPackages(lastActivePackages: ActiveUserPackage) {
    this.lastActivePackages = lastActivePackages;
  }

  @action
  setNewAccount() {
    this.newAccount = true;
  }

  @action
  unsetNewAccount() {
    this.newAccount = false;
  }

  @action
  clear() {
    const unixEpoch = new Date(0);
    this.accountType = AccountType.Guest;
    this.email = null;
    this.uuid = null;
    this.newAccount = false;
    this.expireLoginDate = unixEpoch;
    this.isAuthenticated = false;
    this.setLoginMethod(null);
    this.stopIntervalChecking();
  }

  @action
  private loadDataFromLocalStorage() {
    const data = this.localStorage.loadDataFromLocalStorage<this>();

    if (!data) {
      return;
    }

    this.email = data.email;
    this.uuid = data.uuid;
    this.newAccount = data.newAccount;
    this.expireLoginDate = new Date(data.expireLoginDate);
    this.setLoginMethod(data.loginMethod);

    if (!this.isExpired()) {
      this.accountType = AccountType.Authenticated;
    }
  }

  @computed
  get toObject() {
    return {
      email: this.email,
      uuid: this.uuid,
      loginMethod: this.loginMethod,
      isSocialLogin: this.isSocialLogin,
      newAccount: this.newAccount,
      expireLoginDate: this.expireLoginDate,
    };
  }

  @computed
  get toJSON() {
    return JSON.stringify(this.toObject);
  }
}

export default new UserStore();
export { UserStore };
